import { Fragment } from 'react';
import DesktopMegaMenuContent from './DesktopMegaMenuContent';
import DesktopMenuItems from './DesktopMenuItems';
import DesktopMenuItem from './DesktopMenuItem';

export default function MenuTraining(props) {
  return (
    <DesktopMegaMenuContent>
      <DesktopMenuItems>
        <DesktopMenuItem
          title="Información de cursos"
          description="Tipos de cursos de entrenamiento que realizamos con la más alta calidad."
          icon={
            <Fragment>
              <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
            </Fragment>
          }
          url="/entrenamientos"
          {...props}
        />
        <DesktopMenuItem
          title="Calendario de cursos"
          description="Planificación de las clases de los cursos de entrenamiento."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/clases"
          {...props}
        />
        <DesktopMenuItem
          title="Entrenadores"
          description="Conoce a los entrenadores que forman parte de nuestra comunidad."
          icon={
            <Fragment>
              <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
            </Fragment>
          }
          url="/entrenadores"
          {...props}
        />
        <DesktopMenuItem
          title="Formación de entrenadores"
          description="Sé parte del movimiento ágil y garantiza la correcta adopción de Scrum."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/formacion-de-entrenadores-scrum"
          {...props}
        />
      </DesktopMenuItems>
    </DesktopMegaMenuContent>
  );
}

import PropTypes from 'prop-types';
import Container from '@/components/Container';
import MenuCommon from '@/view/MainMenu/MenuCommon';

export default function DesktopMegaMenuContent({
  rightContent,
  bottomContent,
  children,
}) {
  const hasBottomContent = bottomContent !== null;

  return (
    <div className="flex w-full flex-col">
      <div className="relative w-full">
        <Container className="relative z-10">
          <div className="overflow-hidden bg-white xl:grid xl:grid-cols-12">
            <div className="lg:py-10 xl:col-span-8 xl:py-12 xl:pr-10">
              {children}
            </div>
            <div className="hidden xl:col-span-4 xl:flex">
              <div className="flex w-full bg-gray-100 lg:rounded lg:px-4 lg:py-5">
                {rightContent || <MenuCommon />}
              </div>
            </div>
          </div>
        </Container>
        <div className="absolute left-0 top-0 bottom-0 z-0 w-2/4 bg-white" />
        <div className="absolute right-0 top-0 bottom-0 z-0 hidden w-2/4 bg-gray-100 xl:block" />
      </div>
      {hasBottomContent && (
        <div className="h-20 border-t bg-gradient-to-r from-green-50 via-green-100 to-white" />
      )}
    </div>
  );
}

DesktopMegaMenuContent.defaultProps = {
  rightContent: null,
  bottomContent: null,
};

DesktopMegaMenuContent.propTypes = {
  rightContent: PropTypes.node,
  bottomContent: PropTypes.node,
  children: PropTypes.node.isRequired,
};

import { Fragment } from 'react';
import DesktopMegaMenuContent from './DesktopMegaMenuContent';
import DesktopMenuItems from './DesktopMenuItems';
import DesktopMenuItem from './DesktopMenuItem';

export default function MenuAboutCompany(props) {
  return (
    <DesktopMegaMenuContent>
      <DesktopMenuItems>
        <DesktopMenuItem
          title="Quiénes sómos"
          description="Conoce un poco más sobre nosotros, nuestra misión y nuestra visión."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/nosotros"
          {...props}
        />
        <DesktopMenuItem
          title="Consultoría y entrenamiento"
          description="Proveemos capacitaciones y entrenamientos a los equipos en tu organización."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/consultoria-y-entrenamiento"
          {...props}
        />
        <DesktopMenuItem
          title="Clientes"
          description="Contamos con la confianza de empresas e instituciones reconocidas."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/clientes"
          {...props}
        />
        <DesktopMenuItem
          title="Contáctenos"
          description="Mantengamos el contacto para trabajar juntos en su proyecto."
          icon={
            <Fragment>
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </Fragment>
          }
          url="/contacto"
          {...props}
        />
      </DesktopMenuItems>
    </DesktopMegaMenuContent>
  );
}

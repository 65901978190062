import PropTypes from 'prop-types';

export default function DesktopMenuItems({ children }) {
  return (
    <nav className="grid w-full grid-cols-2 gap-10 pb-4 pr-2">{children}</nav>
  );
}

DesktopMenuItems.defaultProps = {};

DesktopMenuItems.propTypes = {
  children: PropTypes.node.isRequired,
};

import { Fragment } from 'react';
import DesktopMegaMenuContent from './DesktopMegaMenuContent';
import DesktopMenuItems from './DesktopMenuItems';
import DesktopMenuItem from './DesktopMenuItem';

export default function MenuResources(props) {
  return (
    <DesktopMegaMenuContent>
      <DesktopMenuItems>
        <DesktopMenuItem
          title="Libros"
          description="Descarga libros de autores de nuestra comunidad y otros autores destacados."
          icon={
            <Fragment>
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
            </Fragment>
          }
          url="/recursos/libros"
          {...props}
        />
        <DesktopMenuItem
          title="Exámenes de práctica"
          description="Mide tus conocimientos básicos de Scrum con nuestros exámenes de práctica."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/examenes-de-practica"
          {...props}
        />
        <DesktopMenuItem
          title="Materiales de Scrum"
          description="Descubre qué es el método Scrum a través de estos materiales sencillos y prácticos."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/recursos/materiales"
          {...props}
        />
        <DesktopMenuItem
          title="Uso de la marca"
          description="Recursos sobre los elementos fundamentales de nuestra marca."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="https://marca.aibes.org/"
          {...props}
        />
      </DesktopMenuItems>
    </DesktopMegaMenuContent>
  );
}

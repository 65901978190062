import PropTypes from 'prop-types';
import DesktopMegaMenuContainer from './DesktopMegaMenuContainer';
import MenuAboutCompany from './MenuAboutCompany';
import MenuPartners from './MenuPartners';
import MenuTraining from './MenuTraining';
import MenuCommunity from './MenuCommunity';
import MenuResources from './MenuResources';
import { Fragment } from 'react';

export default function DesktopMegaMenu({
  labelledby,
  itemSlug,
  isHover,
  onMenuHover,
  onMenuClick,
}) {
  const isAboutActive = itemSlug === 'about' && isHover;
  const isPartnersActive = itemSlug === 'partners' && isHover;
  const isTrainingActive = itemSlug === 'training' && isHover;
  const isCommunityActive = itemSlug === 'community' && isHover;
  const isResourcesActive = itemSlug === 'resources' && isHover;

  const isActive =
    itemSlug !== null &&
    (isAboutActive ||
      isPartnersActive ||
      isTrainingActive ||
      isCommunityActive ||
      isResourcesActive);

  return (
    <div
      className="absolute top-0 left-0 right-0 z-40 w-full mt-20 bg-white shadow-xl focus:outline-none"
      tabIndex="-1"
    >
      {isActive && (
        <div className="absolute top-0 z-50 w-full h-px bg-gray-600 opacity-10" />
      )}
      <Fragment>
        <DesktopMegaMenuContainer
          isActive={isAboutActive}
          onMenuHover={onMenuHover}
        >
          <MenuAboutCompany onItemClick={onMenuClick} />
        </DesktopMegaMenuContainer>
        <DesktopMegaMenuContainer
          isActive={isPartnersActive}
          onMenuHover={onMenuHover}
        >
          <MenuPartners onItemClick={onMenuClick} />
        </DesktopMegaMenuContainer>
        <DesktopMegaMenuContainer
          isActive={isTrainingActive}
          onMenuHover={onMenuHover}
        >
          <MenuTraining onItemClick={onMenuClick} />
        </DesktopMegaMenuContainer>
        <DesktopMegaMenuContainer
          isActive={isCommunityActive}
          onMenuHover={onMenuHover}
        >
          <MenuCommunity onItemClick={onMenuClick} />
        </DesktopMegaMenuContainer>
        <DesktopMegaMenuContainer
          isActive={isResourcesActive}
          onMenuHover={onMenuHover}
        >
          <MenuResources onItemClick={onMenuClick} />
        </DesktopMegaMenuContainer>
      </Fragment>
    </div>
  );
}

DesktopMegaMenu.defaultProps = {
  labelledby: 'menu',
  itemSlug: null,
  isHover: false,
  isShown: true,
  onMenuHover: () => null,
  onMenuClick: () => null,
};

DesktopMegaMenu.propTypes = {
  labelledby: PropTypes.string,
  itemSlug: PropTypes.oneOf([
    'about',
    'partners',
    'training',
    'community',
    'resources',
  ]),
  isHover: PropTypes.bool,
  isShown: PropTypes.bool,
  onMenuHover: PropTypes.func,
  onMenuClick: PropTypes.func,
};

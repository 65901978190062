import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from '@/components/Link';

export default function DesktopMenuItem({
  title,
  description,
  icon,
  url,
  onItemClick,
}) {
  return (
    <Link
      onClick={onItemClick}
      className="flex items-start space-x-3"
      href={url}
    >
      {({ isHovering }) => (
        <>
          <div className="flex items-center h-7">
            <svg
              className={clsx(
                'h-6 w-6 transition duration-300 ease-in-out',
                isHovering === true && 'text-green-500',
                isHovering === false && 'text-gray-300'
              )}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              {icon}
            </svg>
          </div>
          <div className="flex flex-col flex-1 space-y-2">
            <h4 className="flex items-center space-x-1">
              <div
                className={clsx(
                  'text-lg font-semibold leading-7',
                  isHovering === true && 'text-gray-700',
                  isHovering === false && 'text-gray-900'
                )}
              >
                {title}
              </div>
              <div className="w-4 overflow-hidden">
                <svg
                  className={clsx(
                    'h-4 w-4 transform text-gray-500 transition duration-500 ease-in-out',
                    isHovering === true && 'translate-x-0 opacity-100',
                    isHovering === false && '-translate-x-2 opacity-0'
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </h4>
            <div
              className={clsx(
                'text-sm transition duration-300 ease-in-out',
                isHovering === true && 'text-gray-800',
                isHovering === false && 'text-gray-500'
              )}
            >
              {description}
            </div>
          </div>
        </>
      )}
    </Link>
  );
}

DesktopMenuItem.defaultProps = {
  onItemClick: () => null,
};

DesktopMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  onItemClick: PropTypes.func,
};

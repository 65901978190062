import { Fragment } from 'react';
import DesktopMegaMenuContent from './DesktopMegaMenuContent';
import DesktopMenuItems from './DesktopMenuItems';
import DesktopMenuItem from './DesktopMenuItem';

export default function MenuTraining(props) {
  return (
    <DesktopMegaMenuContent>
      <DesktopMenuItems>
        <DesktopMenuItem
          title="Blog"
          description="Lecturas sobre buenas prácticas, métodos y experiencias ágiles."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                clipRule="evenodd"
              />
              <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
            </Fragment>
          }
          url="/blog"
          {...props}
        />
        <DesktopMenuItem
          title="Eventos"
          description="Participa en los eventos organizados por nuestra comunidad."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/eventos"
          {...props}
        />
        <DesktopMenuItem
          title="Validación de certificaciones"
          description="Accede a la herramienta de validación de certificaciones."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="/validar-certificacion"
          {...props}
        />
      </DesktopMenuItems>
    </DesktopMegaMenuContent>
  );
}

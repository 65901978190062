import { Fragment } from 'react';
import DesktopMegaMenuContent from './DesktopMegaMenuContent';
import DesktopMenuItems from './DesktopMenuItems';
import DesktopMenuItem from './DesktopMenuItem';

export default function MenuPartners(props) {
  return (
    <DesktopMegaMenuContent>
      <DesktopMenuItems>
        <DesktopMenuItem
          title="Red de socios"
          description="Conozca a las organizaciones autorizadas que pertenecen a nuestra red."
          icon={
            <Fragment>
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </Fragment>
          }
          url="/socios"
          {...props}
        />
        <DesktopMenuItem
          title="Portal de socios"
          description="Acceda al portal exclusivo para nuestra red de socios y entrenadores."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="https://socios.aibes.org/"
          {...props}
        />
        <DesktopMenuItem
          title="Programa de socios"
          description="Trabajemos juntos para acelerar el impacto en la adopción de la cultura ágil de nuestros clientes."
          icon={
            <Fragment>
              <path
                fillRule="evenodd"
                d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                clipRule="evenodd"
              />
            </Fragment>
          }
          url="https://socios.aibes.org/"
          {...props}
        />
      </DesktopMenuItems>
    </DesktopMegaMenuContent>
  );
}

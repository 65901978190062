import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function DesktopMegaMenuContainer({
  isActive,
  onMenuHover,
  children,
}) {
  const ref = useRef(null);

  const handleMouseOver = () => onMenuHover(true);
  const handleMouseOut = () => onMenuHover(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseenter', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);

        return () => {
          node.removeEventListener('mouseenter', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  if (isActive === false) {
    return null;
  }

  return (
    <div className="relative w-full" ref={ref}>
      {children}
    </div>
  );
}

DesktopMegaMenuContainer.defaultProps = {
  isActive: false,
  onMenuHover: () => null,
};

DesktopMegaMenuContainer.propTypes = {
  isActive: PropTypes.bool,
  onMenuHover: PropTypes.func,
  children: PropTypes.node.isRequired,
};
